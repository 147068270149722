<template>
  <div>
    <header class="breadcrumb">
      <span class="title">当前位置：</span>
      <el-breadcrumb separator-class="el-icon-arrow-right">
        <el-breadcrumb-item :to="{ path: '/home-login-out' }">首页</el-breadcrumb-item>
        <el-breadcrumb-item>我要申请</el-breadcrumb-item>
      </el-breadcrumb>
      <router-link to="/home-login-out">返回上级</router-link>
    </header>

    <div class="main-body flex">
      <div class="container_wysq_icon">
        <div
          v-for="(item, index) in iconTab"
          :key="index"
          :title="item.title"
          class="iconTab"
          :class="{
            currentShow: currentStep[index],
            otherShow: !currentStep[index],
          }"
        >
          <img v-show="!currentStep[index]" :src="item.path" />
          <img v-show="currentStep[index]" :src="item.paths" class="hover-img" />
          <div class="wysq-title" :class="{ 'title-color': currentStep[index] }">
            {{ item.title }}
          </div>
          <div class="ljx" v-show="index !== iconTab.length - 1"></div>
        </div>
      </div>
      <div class="flex-1 container_wysq_content">
        <div v-if="urlName === 'wysq-ydxz'">
          <ydxz></ydxz>
        </div>
        <div v-if="urlName === 'wysq-bdclx'">
          <bdclx></bdclx>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from 'axios';
import stepService from '@iebdc/api/wysq/step-service';
import ydxz from './ydxz';
import bdclx from './bdclx';
export default {
  data() {
    return {
      //所有左侧栏步骤集合
      allIconTab: [
        {
          title: '阅读须知',
          path: require('@iebdc/assets/images/wysq/ydxz.png'),
          paths: require('@iebdc/assets/images/wysq/ydxz1.png'),
        },
        {
          title: '不动产类型',
          path: require('@iebdc/assets/images/wysq/bdclx.png'),
          paths: require('@iebdc/assets/images/wysq/bdclx1.png'),
        },
        {
          title: '登记类型',
          path: require('@iebdc/assets/images/wysq/djlx.png'),
          paths: require('@iebdc/assets/images/wysq/djlx1.png'),
        },
        {
          title: '产权证核验',
          path: require('@iebdc/assets/images/wysq/cqzhy.png'),
          paths: require('@iebdc/assets/images/wysq/cqzhy1.png'),
        },
        {
          title: '填写申请表',
          path: require('@iebdc/assets/images/wysq/txsqb.png'),
          paths: require('@iebdc/assets/images/wysq/txsqb1.png'),
        },
        {
          title: '询问笔录',
          path: require('@iebdc/assets/images/wysq/xwbl.png'),
          paths: require('@iebdc/assets/images/wysq/xwbl1.png'),
        },
        {
          title: '抵押合同',
          path: require('@iebdc/assets/images/wysq/dyht.png'),
          paths: require('@iebdc/assets/images/wysq/dyht1.png'),
        },
        {
          title: '材料上传',
          path: require('@iebdc/assets/images/wysq/scfj.png'),
          paths: require('@iebdc/assets/images/wysq/scfj1.png'),
        },
        {
          title: '权属核验',
          path: require('@iebdc/assets/images/wysq/hy1.png'),
          paths: require('@iebdc/assets/images/wysq/hy.png'),
        },
        {
          title: '完成',
          path: require('@iebdc/assets/images/wysq/wc.png'),
          paths: require('@iebdc/assets/images/wysq/wc1.png'),
        },
      ],
      //选中状态数组
      currentStep: [],
      //选中步骤页面名称
      urlName: '',
      //拥有权限的步骤集合
      iconTab: [],
    };
  },
  components: {
    ydxz,
    bdclx,
  },
  mounted() {
    axios.get(`${process.env.VUE_APP_BASE_URL}sq.json`).then((res) => {
      let sqJson = res.data;
      //左侧导航图标数据
      this.iconTab = stepService.getIconTab(sqJson, this.allIconTab);
      //初始化选中状态
      this.currentStep = stepService.getCurrentStep(this.iconTab);
      //判断状态跳转
      if (this.$store.getters.getWwywh) {
        this.goStep('txsqb');
      } else {
        this.goStep('ydxz');
      }
    });
  },
  methods: {
    goStep(item, type) {
      const nextStep = stepService.getUrl(item, this.currentStep, this.iconTab, type);
      this.currentStep = nextStep.step;
      this.urlName = nextStep.url;
    },
  },
};
</script>

<style lang="scss" scoped>
.breadcrumb {
  display: inline-block;
  height: 40px;
  font-size: 13px;
  line-height: 40px;
  width: 100%;
  .title {
    vertical-align: top;
    margin-right: 10px;
  }
  a {
    float: right;
    color: #145ccd;
  }
  .el-breadcrumb {
    font-size: 13px;
    line-height: 40px;
    display: inline-block;
  }
}
.container_wysq_content {
  overflow: auto;
  margin-top: 20px;
  margin-bottom: 20px;
  margin-left: 20px;
}
.container_wysq_icon {
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  padding-left: 16px;
  width: 135px;
  margin-top: 20px;
  .iconTab {
    text-align: center;
    border-radius: 10px;
    height: 74px;
    width: 74px;
    margin-bottom: 38px;
    line-height: 40px;
    img {
      vertical-align: bottom;
    }
    .wysq-title {
      margin-top: -5px;
    }
    .title-color {
      color: white;
    }
    .ljx {
      height: 38px;
      border-left: solid rgba(243, 243, 243, 1) 3px;
      margin-left: 37px;
    }
  }
  .currentShow {
    background-color: #167cc7;
  }
  .otherShow {
    background-color: rgba(243, 243, 243, 1);
  }
}
</style>
