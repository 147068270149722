<template>
  <div class="container_wysq_ydxz" style="text-indent:25px">
    <h2 style="text-align: center;">{{ systemName }}申请须知及协议</h2>
    <p>
      欢迎使用{{
        systemName
      }}。为方便公众申请不动产登记，现开通服务大厅功能。申请人在申请前须仔细阅读本须知。
    </p>
    <p>
      一、办理流程：公众外网提交预申请，不动产登记机构预审理，内网受理审核案卷。申请人收到核验通知后，全部申请人携带纸质申请材料原件到现场核验，当场缴纳税费。
    </p>
    <p>
      二、申请人收到核验通知后，请及时核验。超出核验时间30天未核验的，系统将自动注销该网上预申请。
    </p>
    <p>
      三、服务大厅采用实名制，网上申请人应是不动产权利人或权利人依法授权的代理人，并具备完全民事行为能力。
    </p>
    <p>
      <span style="color: red;"
        >四、网上申请人应认真如实填写有关申请信息，所申请事项及上传的影像材料务必真实、合法、有效。申请人提供虚假材料、虚假信息的，将列入诚信黑名单，并承担相应法律责任。</span
      >
    </p>
    <p>
      五、服务大厅为预申请，申请人外网提交后，不动产登记机构进行预审核。在核验前若有任意一方申请人提出撤回预申请，可通过系统网上撤回该网上预申请。因撤回网上预申请产生的纠纷由撤回的申请人负责。
    </p>
    <p>
      <span style="color: red;"
        >本人承诺：本申请系申请人真实意思的表示,并对所填写的上述内容及提交的申请材料真实性负责，
        如有不实，愿承担法律责任。</span
      >
    </p>
    <div class="checkxz">
      <input
        type="checkbox"
        @click="bAlreadyUserRegister = !bAlreadyUserRegister"
        :checked="!bAlreadyUserRegister"
      />
      <span class="ty">我已阅读以上须知并同意</span>
    </div>
    <div class="btn-container" style="text-indent:0px">
      <button
        class="btn btn-next-color"
        @click="$parent.goStep('ydxs', 'next')"
        :disabled="bAlreadyUserRegister || timeCount > 0"
      >
        下一步
        <span v-if="timeCount > 0" style="padding: 5px;"
          >({{ timeCount }})</span
        >
      </button>
    </div>
  </div>
</template>

<script>
export default {
  name: 'ydxz',
  data() {
    return {
      systemName: window.CONFIG.APP_INFO.systemName,
      bAlreadyUserRegister: true,
      timeCount: 0
    };
  },
  mounted() {
    this.addInteral();
  },
  methods: {
    /**
     * 倒计时
     * @param {Object} $scope 作用域
     */
    addInteral() {
      let timeCount = 10;
      setInterval(
        function() {
          timeCount--;
          this.timeCount = timeCount;
        },
        1000,
        10
      );
    }
  }
};
</script>

<style lang="scss" scoped>
.container_wysq_ydxz {
  background-color: rgba(243, 243, 243, 1);
  padding: 20px;
  line-height: 40px;
  .checkxz {
    text-align: center;
    .ty {
      margin-right: 50px;
      color: rgba(207, 23, 25, 1);
      margin-left: 5px;
    }
  }
  .btn-container {
    text-align: center;
    margin: 20px;
  }
}
p {
  margin: 0 0 10px;
}
h2 {
  font-size: 20px;
  margin-top: 20px;
  margin-bottom: 10px;
}
.btn-next-color {
  width: 92px;
  border-color: #167cc7;
  background-color: #167cc7;
  color: white;
  border-radius: 0;
  margin-right: 10px;
}
</style>
