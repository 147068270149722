<template>
  <div ng-controller="bdclxCtrl" class="container_wysq_bdclx">
    <div class="checkBdclx" :class="{ 'qy-checkBdclx': hasPermission() || hasPermission() }">
      <div
        v-for="(item, index) in bdclxData"
        :key="index"
        :title="item.title"
        class="bdclx-content"
        :class="{ 'bdclx-background': selectedBdclx[index] }"
        @click="checkBdclx(index)"
      >
        <img :src="item.path" class="bdclx-icon" v-show="hasPermission(item.permission)" />
        <img :src="item.pathgray" class="bdclx-icon" v-show="!hasPermission(item.permission)" />
        <div class="bdclx-title" :class="{ 'title-color': selectedBdclx[index] }">
          {{ item.title }}
        </div>
      </div>
    </div>
    <div class="btn-container">
      <button class="btn btn-next-color" type="button" @click="$parent.goStep('bdclx', 'previous')">
        上一步
      </button>
      <button class="btn btn-next-color" type="button" @click="$parent.goStep('bdclx', 'next')">
        下一步
      </button>
    </div>
  </div>
</template>

<script>
import bdclxService from '@iebdc/api/wysq/bdclx-service';
import { IEBDC_USER_DATA } from '@iebdc/config/app-data';
export default {
  name: 'bdclx',
  data() {
    return {
      bdclx: [
        {
          title: '房屋',
          value: 'HOUSE',
          path: require('@iebdc/assets/images/wysq/house.png'),
          pathgray: require('@iebdc/assets/images/wysq/housegray.png'), 
          permission: 'IEBDC:SQ:BDCLX:FW',
          hasPermission: 'IEBDC:SQ:BDCLX:FWXS'
        },
        {
          title: '土地',
          value: 'LAND',
          path: require('@iebdc/assets/images/wysq/land.png'),
          pathgray: require('@iebdc/assets/images/wysq/landgray.png'),
          permission: 'IEBDC:SQ:BDCLX:TD',
          hasPermission: 'IEBDC:SQ:BDCLX:TDXS'
        },
        {
          title: '林木',
          value: 'WOODLAND',
          path: require('@iebdc/assets/images/wysq/wood.png'),
          pathgray: require('@iebdc/assets/images/wysq/woodgray.png'),
          permission: 'IEBDC:SQ:BDCLX:LM',
          hasPermission: 'IEBDC:SQ:BDCLX:LMXS'
        }
      ],
      //开放的不动产类型
      bdclxData: [],
      //选中的不动产类型状态
      selectedBdclx: [],
      //选中的不动产类型
      firstFlow: {
        userId: IEBDC_USER_DATA().userId,
        sqrlx: '',
        djjg: '',
        jgmc: '',
        bsdt: '',
        bsdtName: ''
      }
    };
  },
  mounted() {
    //获取有权限的不动产类型
    this.bdclxData = bdclxService.getBdclx(this.bdclx);
    //默认选择房屋
    this.checkBdclx(0);
  },
  methods: {
    //是否有权限
    hasPermission(label) {
      return this.$store.getters.hasPermission(label);
    },
    //选择不动产类型
    checkBdclx(index) {
      //选中状态改变
      this.selectedBdclx = [];
      const selectedBdclx = bdclxService.selectBdclx(index, this.selectedBdclx, this.bdclxData, (permission) => {
        return this.hasPermission(permission);
      });
      this.selectedBdclx = selectedBdclx.selectStep;
      if (selectedBdclx.hasPermission) {
        this.firstFlow.bdclb = this.bdclxData[index].value;
        //将第一次保存的信息缓存下来
        this.$store.dispatch('setFirstFlow', this.firstFlow);
      }
    }
  }
};
</script>

<style lang="scss" scoped>
.container_wysq_bdclx {
  flex: 1;
  //我要申请
  .checkBdclx,
  .qy-checkBdclx {
    height: 60%;
    display: flex;
    justify-content: space-around;
    padding-top: 25%;
    .bdclx-content {
      width: 203px;
      height: 160px;
      cursor: pointer;
      .bdclx-icon {
        padding-left: 51px;
        padding-top: 20px;
      }
      .bdclx-title {
        line-height: 40px;
        text-align: center;
      }
      .title-color {
        color: rgba(77, 149, 208, 1);
      }
    }
    .bdclx-background {
      background-color: rgba(234, 241, 247, 1);
    }
  }
  //企业申请
  .qy-checkBdclx {
    height: auto;
    padding: 3% 20% 2% 20%;
    .bdclx-content {
      width: 130px;
      height: 120px;
      .bdclx-icon {
        padding-left: 15px;
        padding-top: 10px;
      }
    }
  }
  .lpb {
    margin-top: 15px;
    .lpb-tree {
      float: left;
      width: 160px;
      margin: 10px;
      .tree-nav {
        overflow: inherit;
        li {
          a,
          a:active,
          a:hover {
            height: 35px;
            line-height: 30px;
            padding: 0px;
            display: block;
            overflow: hidden;
          }
          .tip {
            position: absolute;
            line-height: 20px;
            width: 200%;
            height: 25px;
            top: 5px;
            text-align: left;
            left: 160px;
            z-index: 100;
            span {
              background-color: #fff;
              border: 1px solid #ddd;
              padding: 3px 5px;
            }
          }
        }
      }
    }
    .lpb-center {
      flex: 1;
      padding-left: 20px;
      position: relative;
      top: 5px;
      margin-left: 160px;
      .lpb-query {
        height: 130px;
        .lpb-query-list {
          display: flex;
          float: left;
          height: 40px;
          width: 400px;
          padding: 10px 0px 0px 0px;
          > label {
            width: 90px;
            line-height: 30px;
          }
          > div {
            margin: 0px 20px 0px 0px;
            width: 300px;
          }
        }
      }
      .query-lpb {
        margin: 5px 50px;
      }
      .query-lpb-qylx2 {
        width: 750px;
        margin: 30px 0px 10px 0px;
        .form-input {
          width: 465px;
        }
        input {
          height: 35px !important;
        }
        .search {
          .btn {
            height: 35px;
          }
        }
      }
    }
    .lpb-table {
      left: 20px;
      top: 20px;
      width: 780px;
      position: relative;
      overflow: auto;
      margin-top: 8px;
      margin-left: 160px;
    }
  }
}
.btn-container {
  text-align: center;
  margin: 20px;
}
.btn-next-color {
  width: 92px;
  border-color: #167cc7;
  background-color: #167cc7;
  color: white;
  border-radius: 0;
  margin-right: 10px;
}
</style>
