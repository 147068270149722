import store from '@iebdc/store'
const bdclxService = {
  //获取不动产类型
  getBdclx(bdclx) {
    let array = [];
    bdclx.forEach(item => {
      if (store.getters.hasPermission(item.hasPermission)) {
        array.push(item);
      }
    });
    return array;
  },
  /*
     * 选中不动产类型
     * @param num 选中的第几个
     * @param {array} selectedBdclx 选中的不动产类型状态
     * bdclx 不动产类型数据集合
     * callback 判断权限函数
     */
  selectBdclx(num, selectedBdclx, bdclx, callback) {
    let array = selectedBdclx;
    let hasPermission = true;
    bdclx.forEach((item, index) => {
      if (index === num) {
        array[index] = true;
        if (!callback(bdclx[index].permission)) {
          this.$message({
            message: '暂不开通此类业务',
            type: 'warning'
          });
          hasPermission = false;
          array = selectedBdclx;
          return { selectStep: array, hasPermission: hasPermission };
        }
      } else {
        array[index] = false;
      }
    });
    return { selectStep: array, hasPermission: hasPermission };
  },
};
export default bdclxService;
