import store from '@iebdc/store'
const stepService = {
  //获取流程步骤
  /*
   * sqJson 获取到的步骤json
   * iconTab 所有步骤图标集合
  */
  getIconTab(sqJson, iconTab) {
    let arrayTab = [];
    let firstFlow = store.getters.getFirstFlow;
    sqJson.forEach(item => {
      iconTab.forEach(icon => {
        if ((item.title === icon.title) && store.getters.hasPermission(item.permission)) {
          switch (item.title) {
            case '权属核验':
              if (item.code.indexOf(firstFlow.netFlowdefCode) !== -1) {
                icon.url = item.url;
                arrayTab.push(icon);
              }
              break;
            case '产权证核验':
              if (item.code.indexOf(firstFlow.netFlowdefCode) !== -1) {
                icon.url = item.url;
                arrayTab.push(icon);
              }
              break;
            default:
              icon.url = item.url;
              arrayTab.push(icon);
              break;
          }
        }
      });
    });
    return arrayTab;
  },
  //获取选中状态
  getCurrentStep(iconTabUrl) {
    const currentStep = iconTabUrl.map(() => {
      return false;
    });
    return currentStep;
  },
  /*
     * 获取跳转页面的url
     * @param item 跳转到页面 例如'txsqb'
     * @param currentStep 当前选中的状态
     * @param iconTab 申请配置array
     * @param type 上一页还是下一页
     * return url 跳转页面的url step第几个页面
     */
  getUrl(item, currentStep, iconTab, type) {
    let url = {};
    let stepIndex = '';
    let step = currentStep;
    iconTab.forEach((vm, index) => {
      step[index] = false;
      if (vm.url.indexOf(item) >= 0) {
        stepIndex = index;
      }
    });
    if (type === 'next' && iconTab[stepIndex + 1]) {
      step[stepIndex + 1] = true;
      url = iconTab[stepIndex + 1].url;
    } else if (type === 'previous' && iconTab[stepIndex - 1]) {
      step[stepIndex - 1] = true;
      url = iconTab[stepIndex - 1].url;
    } else {
      step[stepIndex] = true;
      url = iconTab[stepIndex].url;
    }
    return { url: url, step: step };
  },
};
export default stepService;
